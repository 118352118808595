import cn from 'classnames';
import canonical from 'components/seo/canonical';
import description from 'components/seo/description';
import image from 'components/seo/image';
import robots from 'components/seo/robots';
import title from 'components/seo/title';
import seotype from 'components/seo/type';
import useMobile from 'hooks/common/use-mobile';
import useRefresh from 'hooks/common/use-refresh';
import StandaloneCDP from 'modules/collection/components/standalone-cdp';
import { useContentful } from 'modules/content-cms/components/context';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';
import { Organization } from 'schema-dts';
import HomeFteSegmentationButton from '../components/home-fte-segmentation-button';
import InfluencerCollectionSlider from '../components/influencer-colletion-slider';
import ShowMoreButton from '../components/show-more-button';
import bowlImage from './bowl.png';
import bowlImageMobile from './bowl_mobile.png';
import GesundSvg from './pd_petneed_icon_gesund-beginnt-im-napf.svg';

const Landingpage: React.FC = () => {
  const { data } = useContentful();
  const [petType, setPetType] = useState<null | 'dog' | 'cat'>(null);
  const isMobile = useMobile();
  const { push } = useRouter();
  useRefresh();

  const expandCallBack = useCallback(
    ({
      isOpen,
      petType,
    }: {
      isOpen: boolean;
      petType: 'cat' | 'dog' | null;
    }) => {
      if (petType === 'cat' && !isMobile) {
        push('pages/katzen');
        return;
      } else if (isOpen) {
        setPetType(petType);
      } else {
        setPetType(null);
      }
    },
    [isMobile, push]
  );

  return (
    <>
      <Head>
        {title(data.fields.headline)}
        {description(data.fields.metaDescription)}
        {seotype()}
        {robots(data.fields.metaRobots)}
        {canonical('')}
        {image(
          data.fields.metaOgImage && data.fields.metaOgImage.fields.file.url
        )}
        <script
          {...jsonLdScriptProps<Organization>({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            '@id': '#organization',
            sameAs: [
              'https://www.facebook.com/petsdeli',
              'https://www.instagram.com/petsdeli',
              'https://www.pinterest.de/petsdeli',
              'https://www.linkedin.com/company/pets-deli',
            ],
            name: 'Pets Deli',
            url: 'https://www.petsdeli.de',
            legalName: 'Pets Deli Tonius GmbH',
            address: {
              '@type': 'PostalAddress',
              streetAddress: 'Winsstr. 62',
              addressLocality: 'Berlin',
              addressRegion: 'DE-BE',
              addressCountry: 'GER',
              postalCode: '10405',
            },
            logo: {
              '@type': 'ImageObject',
              caption: 'Pets Deli',
              url: 'https://www.petsdeli.de/images/petsdeli-logo.svg',
            },
            foundingDate: '2017',
            contactPoint: {
              '@type': 'ContactPoint',
              telephone: '+4930220564405',
              email: 'office@petsdeli.de',
              contactType: 'Customer Support',
            },
          })}
          data-test="organization-schema"
        />
      </Head>

      <InfluencerCollectionSlider
        className="lg:mb-16"
        data-test="influencer-collection-slider"
      />

      <div
        className={cn(
          'relative overflow-hidden bg-blue-base transition-colors ease-in-out',
          petType && 'pb-6 lg:pb-0'
        )}
        data-test="pet-type-container"
      >
        <div
          className="mx-auto my-8 max-w-[289px] lg:mx-auto lg:mb-12 lg:mt-16 lg:max-w-[602px]"
          data-test="gesund-svg-container"
        >
          <GesundSvg data-test="gesund-svg" />
        </div>
        <HomeFteSegmentationButton
          expandCallBack={expandCallBack}
          data-test="home-fte-segmentation-button"
        />

        <div
          className="mx-auto grid w-auto lg:mt-[40px] lg:w-[930px]"
          style={{
            gridTemplateRows: petType ? '0fr' : '1fr',
            transition: '250ms grid-template-rows ease',
          }}
          data-test="bowl-image-container"
        >
          <Image
            src={isMobile ? bowlImageMobile : bowlImage}
            objectFit={'cover'}
            layout={'responsive'}
            quality={100}
            data-test="bowl-image"
          />
        </div>
      </div>
      {petType && (
        <div className="bg-white-broke" data-test="standalone-cdp-container">
          <StandaloneCDP
            className={cn('px-4 py-4 lg:py-8', !isMobile && 'container')}
            collectionHandle={
              petType === 'dog'
                ? 'fur-hunde-alle-fleischsorten'
                : 'fur-katzen-alle-fleischsorten'
            }
            productsLimit={6}
            title="landingpage:standalone-cdp:headline"
            subTitle="landingpage:standalone-cdp:home:sub-headline"
            customButton={
              <ShowMoreButton petType={petType} data-test="show-more-button" />
            }
            data-test="standalone-cdp"
          />
        </div>
      )}
    </>
  );
};

export default Landingpage;
