import cn from 'classnames';
import Button from 'components/button-new';
import { useCollectionState } from 'modules/collection/state';
import { useMemo } from 'react';

type ShowMoreButtonProps = {
  petType?: 'dog' | 'cat';
};

/**
 * Button used in the Home FTE ad the end of the collection part to redirect to the collection page
 */
const ShowMoreButton: React.FC<ShowMoreButtonProps> = ({ petType = 'dog' }) => {
  const { appliedFilter } = useCollectionState();

  const collections = useMemo(() => {
    return petType === 'dog'
      ? [
          { name: 'Trockenfutter', handle: 'fur-hunde-trockenfutter' },
          { name: 'Nassfutter', handle: 'fur-hunde-nassfutter' },
          { name: 'BARF', handle: 'fur-hunde-barf-frische-menus' },
          { name: 'Snacks', handle: 'fur-hunde-snacks' },
        ]
      : [];
  }, [petType]);

  let route =
    petType === 'dog'
      ? 'fur-hunde-alle-fleischsorten'
      : 'fur-katzen-alle-fleischsorten';
  let text = 'Alle Produkte';

  if (petType === 'cat') {
    text = 'Mehr';
  } else if (appliedFilter.length === 1) {
    const selectedCollection = collections.find(
      (c) => c.name.toLowerCase() === appliedFilter[0]
    );

    if (selectedCollection) {
      route = selectedCollection.handle;
      text = `Alle ${selectedCollection.name} Produkte`;
    }
  } else {
    route = 'fur-hunde-alle-fleischsorten';
    text = 'Alle Produkte';
  }

  return (
    <div className="my-4 flex justify-center">
      <Button
        layout="outline"
        size="base"
        href={`/collections/${route}`}
        className={cn('lg:w-1/3', text === 'Alle Produkte' ? 'w-1/2' : 'w-2/3')}
      >
        {text}
      </Button>
    </div>
  );
};

export default ShowMoreButton;
