import { useRouter } from 'next/router';
import { useEffect } from 'react';

/**
 * Refresh the current page checking the query param of "refresh"
 */
const useRefresh = () => {
  const { replace, reload, query, pathname } = useRouter();

  useEffect(() => {
    if (query['refresh']) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { refresh, ...others } = query;
      replace(
        {
          pathname,
          query: others,
        },
        undefined,
        { shallow: true }
      ).then(reload);
    }
  });
};

export default useRefresh;
