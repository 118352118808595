import cn from 'classnames';
import Button from 'components/button-new';
import ImpressionTrackerNew from 'components/impression-tracker/new-impression-tracker';
import { usePetContext } from 'contexts/pet';
import useMobile from 'hooks/common/use-mobile';
import PetNeedsSegmentationDropDown from 'modules/landingpage/components/segementation-drop-down';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { gtm, selectElement } from 'tracking';
import BreedSegmentationSlider from '../breed-segmentation-slider';
import ModalInformation from '../modal-information';
type Props = {
  expandCallBack?: (arg: {
    isOpen: boolean;
    petType: 'dog' | 'cat' | null;
  }) => void;
  shouldShowDropDown?: boolean;
};

const EFFECT_DELAY = 1600;

/**
 * Segmentation button for Home Fte
 * For laptop cat user, it doesn't show any segmentation touch point.
 */
const HomeFteSegmentationButton: React.FC<React.PropsWithChildren<Props>> = ({
  expandCallBack,
  shouldShowDropDown = true,
}) => {
  const { switchContext } = usePetContext();
  const [petType, setPetType] = useState<null | 'dog' | 'cat'>(null);
  const ref = useRef<HTMLDivElement>(null);
  const isMobile = useMobile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isLaptopCatUser = useMemo(() => {
    return petType === 'cat' && !isMobile;
  }, [isMobile, petType]);
  const [dogButton, setDogButton] = useState(false);
  const [catButton, setCatButton] = useState(false);

  useEffect(() => {
    if (petType) {
      switchContext(petType === 'dog' ? 'dogs' : 'cats');
    }
  }, [petType, switchContext]);

  useEffect(() => {
    if (petType) {
      switchContext(petType === 'dog' ? 'dogs' : 'cats');
    }
  }, [petType, switchContext]);

  useEffect(() => {
    if (expandCallBack) {
      expandCallBack({
        isOpen: petType !== null,
        petType,
      });
    }
  }, [expandCallBack, petType]);

  useEffect(() => {
    if (!isLaptopCatUser && shouldShowDropDown && petType !== null) {
      setTimeout(() => {
        window.scrollTo({
          top: (ref.current?.offsetTop || 0) - 30,
          behavior: 'smooth',
        });
      }, EFFECT_DELAY);
    }
  }, [isLaptopCatUser, petType, shouldShowDropDown]);

  return (
    <>
      <ImpressionTrackerNew element="Pet Type Selector">
        <div>
          <div className="container">
            <div
              className="m-auto flex justify-center gap-3 whitespace-nowrap text-2xl text-white lg:max-w-2xl lg:gap-5"
              ref={ref}
            >
              {/* align with global change  */}
              <Button
                onClick={() => {
                  setDogButton(!dogButton);
                  setCatButton(false);
                  if (petType === 'dog') {
                    setPetType(null);
                  } else {
                    setPetType('dog');
                    gtm(
                      selectElement({
                        element: ['Pet Type Selector', 'dog'],
                      })
                    );
                  }
                }}
                layout={dogButton ? 'outline' : 'contained'}
                size="large"
                className={cn('max-w-[273px]', { 'bg-white': dogButton })}
                actionDone={dogButton}
                iconLeft={dogButton}
              >
                <FormattedMessage id="common:for-dogs" />
              </Button>

              <Button
                onClick={() => {
                  setCatButton(!catButton);
                  setDogButton(false);
                  if (petType === 'cat') {
                    setPetType(null);
                  } else {
                    setPetType('cat');
                    gtm(
                      selectElement({
                        element: ['Pet Type Selector', 'cat'],
                      })
                    );
                  }
                }}
                layout={catButton ? 'outline' : 'contained'}
                size="large"
                className={cn('max-w-[273px]', { 'bg-white': catButton })}
                actionDone={catButton}
                iconLeft={catButton}
              >
                <FormattedMessage id="common:for-cats" />
              </Button>
            </div>
          </div>
          {!isLaptopCatUser && shouldShowDropDown && petType && (
            <div
              className="grid w-full"
              style={{
                gridTemplateRows: petType ? '1fr' : '0fr',
                transition: '250ms grid-template-rows ease',
              }}
            >
              <div className="my-3 text-center text-white lg:my-6">
                <span className="hidden lg:inline">
                  {petType === 'dog' ? (
                    <>
                      <span className="inline text-lg">
                        <FormattedMessage
                          id="landingpage:home:fte-segmentation:dog:info-text"
                          values={{
                            br: <br />,
                          }}
                        />
                      </span>
                    </>
                  ) : null}
                </span>
                <div className="px-4 text-center lg:hidden">
                  {petType === 'dog' ? (
                    <FormattedMessage
                      id="landingpage:home:fte-segmentation:dog:info-text"
                      values={{
                        br: null,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="landingpage:home:fte-segmentation:cat:info-text"
                      values={{
                        b: (chunks) => <b>{chunks}</b>,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </ImpressionTrackerNew>
      <div
        className={cn(
          'container mb-[14px] justify-center gap-5 lg:mb-0 lg:flex'
        )}
      >
        <div className={'basis-1/3'}>
          {!isLaptopCatUser && shouldShowDropDown && petType && (
            <PetNeedsSegmentationDropDown
              petType={petType}
              location="home-fte"
              isOpen={!!petType}
              onSelectCallback={() => setPetType(null)}
            />
          )}
        </div>
        {!isMobile && petType === 'dog' && (
          <div className="basis-1/3">
            <BreedSegmentationSlider
              location={'home-fte'}
              showAsSelectButton={!isMobile}
              petType={petType}
            />
          </div>
        )}
      </div>
      {isMobile && petType === 'dog' && (
        <div className="px-4">
          <div className="mb-[14px] mt-0.5 font-medium text-white">
            <FormattedMessage id="landingpage:home:breed-selector:headline" />
          </div>
          <BreedSegmentationSlider location={'home-fte'} />
        </div>
      )}
      <ModalInformation isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </>
  );
};

export default HomeFteSegmentationButton;
