import { useRouter } from 'next/router';
import React from 'react';
import PawLoader from '../components/paw-loader';
import ErrorPageComp from '../pages/_error';

type Type = <T extends React.FC<any>>(
  Component: T
) => React.FC<Parameters<T>[0]>;

export interface StatusCode {
  statusCode: number;
}

const withFallback: Type = (Component) => {
  const WrappedWithFallBack = (props) => {
    const router = useRouter();

    if (router.isFallback) {
      return (
        <main className="flex h-full w-full items-center justify-center">
          <PawLoader />
        </main>
      );
    }

    if (props.statusCode === 404) {
      return <ErrorPageComp statusCode={props.statusCode} />;
    }

    return <Component {...props} />;
  };

  return WrappedWithFallBack;
};

export default withFallback;
